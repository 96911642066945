prefix = 'tui-image-editor'

@import 'main.styl'
@import 'gridtable.styl'
@import 'submenu.styl'
@import 'checkbox.styl'
@import 'range.styl'
@import 'position.styl'
@import 'icon.styl'
@import 'colorpicker.styl'
@import 'buttons.styl'
.{prefix}-container.top
    &.{prefix}-top-optimization
      .{prefix}-controls ul
          text-align: right;
      .{prefix}-controls-logo
          display: none;
